.paper-snackbar {
	transition-property: opacity,bottom,left,right,width,margin,border-radius;
	transition-duration: 0.5s;
	transition-timing-function: ease;
	font-size: 18px;
	font-family: PingFangSC-Semibold, "Noto Sans CJK SC", Arial, sans-serif;
	min-height: 14px;
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #fff;
	line-height: 22px;
	padding: 18px 24px;
	bottom: 0;
	opacity: 0;
	z-index: 99;
}

.snackbar-copybing {
	background-color: #e91e63
}
